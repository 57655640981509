import React, {useState} from 'react';
import {SelectorWrapper} from './Styled';

const NumberedSelector = ({children, data}) => {
  const [selected, setSelected] = useState(data[0]);

  return (
    <SelectorWrapper>
      {children}

      <div className="wrapper">
        <div>
          {data.map((item, index) => (
          
            <div
              onClick={() => setSelected(item)}
            >
              <div className={`${item.title === selected.title ? 'selected' : ''}`}></div>
              <h3>0{index + 1} /</h3>
              <h3 className={`${item.title === selected.title ? 'selected' : ''}`}>{item.title}</h3>
            </div>
          ))}
        </div>
        <div selected>{selected.content}</div>
      </div>
    </SelectorWrapper>
  );
};

export default NumberedSelector;
