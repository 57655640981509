import styled from 'styled-components';

export const ConsumerWrapper = styled.div`
  width: 100%;
  max-width: 1268px;
  padding: 24px;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;

  & > div {
  }

  & > h1 {
    font-family: Thicccboi, sans-serif;
  }

  & > .section {
    margin: 80px 0px;
    display: flex;
    flex-gap: 20px;
    justify-content: space-between;

    @media (max-width: 600px) {
      display: block;
    }

    & > div {
      width: 40%;
      
      @media (max-width: 600px) {
        width: calc(100% - 32px);
      }
    }
  }
`;

export const IndustriesWrapper = styled.div`
  max-width: 1268px;
  padding: 24px;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;

  & > .wrapper {
    padding: 0px 24px;
  }

  & > h1 {
    font-family: Thicccboi, sans-serif;
  }
 
  p {
    line-height: 1.6;
  }

  & > .wrapper > .section {
    margin: 80px 0px;
    display: flex;
    flex-gap: 20px;
    justify-content: space-between;

    @media (max-width: 600px) {
      display: block;
    }

    
    & > div {
      width: 40%;
      
      @media (max-width: 600px) {
        width: calc(100% - 32px);
      }
    }
  }
`;

export const SelectorWrapper = styled.div`

  & > p {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .wrapper {
    display: flex;
    
    @media (max-width: 600px) {
      display: block;
    }
    
    & > div:first-child {
      width: 33%;

      @media (max-width: 600px) {
        width: 100%;
      }

      @media (max-width: 600px) {
        width: 100%;
      }

      & > div:hover {
        cursor: pointer;
      }
      & > div {
        padding-top: 8px;

        & > div {
        }
        & > h3:first-child {
          opacity: 0.5;
          margin-bottom: 0px;
        }
        & > h3:last-child {
          margin-top: 0px;
        }
     

        & > h3.selected {
          opacity: 1;
        }
        & > h3 {
          opacity: 0.5;
        }
      }
    }

    & > div:last-child {
      width: 67%;
      display: ${({selected}) => (selected ? 'block' : 'hidden')};

      @media (max-width: 600px) {
        width: calc(100% - 32p);
      }

      & > h2 {
        color: ${({theme}) => theme.palatte.colors.orange};
      }
    }
  }
`;
